<template>
  <div class="bkColor">
    <headTitle :title="title"></headTitle>
    <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
      @cancel="onCancel" style="margin-bottom: 2vh" />
    <div class="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
          finished-text="没有更多了" @load="getPjList">
          <!-- <van-cell v-for="item in list" :key="item"  /> -->
          <div class="listCell" v-for="item in list" :key="item.id" @click="goDetails(item.id)">
            <div class="cellName">{{ item.projectName }}</div>
            <div class="cellText">
              项目进度：{{ stateText(item.projectStatus) }}
            </div>

            <div class="cellText">汇报时间：{{ item.createTime }}</div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="addBtn">
      <img src="../assets/icon/xz_icon.png" alt="" @click="add" />
    </div>
  </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import Vue from "vue";
import { Search, Toast, List, PullRefresh, Cell } from "vant";

Vue.use(Search);
Vue.use(Toast);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Cell);

import { progressList } from "../api/progress";
import { pjStateText } from '../units/units'
export default {
  name: "progressReport",
  components: {
    headTitle,
  },
  data() {
    return {
      title: "项目进度列表",
      value: "",
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      queryFrom: {
        projectStatus: null,
        projectName: "",
        pageSize: 10,
        pageNum: 1,
      },
    };
  },

  methods: {
    getPjList() {
      this.loading = true;
      progressList(this.queryFrom).then((res) => {
        this.list = this.queryFrom.pageNum == 1 ? res.data.rows : this.list.concat(res.data.rows)
        this.refreshing = false;
        this.loading = false
        this.queryFrom.pageNum = this.queryFrom.pageNum + 1;
        console.log(this.queryFrom.pageNum * this.queryFrom.pageSize);
        if (
          res.data.total <=
          this.queryFrom.pageNum * this.queryFrom.pageSize
        ) {
          this.finished = true;
        }
      });
    },
    //获取项目状态
    stateText(t) {
      return pjStateText(t)
    },
    onSearch(val) {
      this.queryFrom.projectName = val

      this.onRefresh()
    },
    onCancel() {
      this.queryFrom.projectName = ''
      this.onRefresh()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.queryFrom.pageNum = 1
      this.list = []
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.getPjList();
    },
    add() {
      this.$router.push({
        path: "/newPjSchedule",
      });
    },
    goDetails(id) {
      this.$router.push({
        path: "/progressDetails", query: { id: id }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bkColor {
  width: 100vw;
  min-height: 100vh;
  background: #f6f6f6;
  position: relative;
}

.search {
  width: 100vw;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  background: #fff;
  font-size: 15px;
  line-height: 8vh;
}

.searchTitle {
  margin-left: 6vw;
}

.list {
  margin-top: 2vh;
}

.listCell {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 5vw 1vh;
  background: #fff;
  text-align: left;
}

.cellName {
  width: 90%;
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: 1px solid #f6f6f6;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

/deep/ .van-list :first-of-type.listCell .cellName {
  border-top: unset;
}

.cellText {
  width: 70%;
  color: #797979;
  margin-bottom: 0.5vh;
  font-size: 0.7rem;
  word-break: break-all;
  line-height: 1;
}

.addBtn {
  width: 70px;
  height: 70px;
  position: fixed;
  top: 77%;
  left: 77%;

  img {
    width: 70px;
    height: 70px;
  }
}
</style>